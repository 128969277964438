import axios from 'axios'

const PINATA_API_KEY = import.meta.env.VITE_PINATA_API_KEY
const PINATA_API_SECRET = import.meta.env.VITE_PINATA_API_SECRET
const PINATA_API_URL = '/api/pinata/pinning/pinFileToIPFS'

if (!PINATA_API_KEY || !PINATA_API_SECRET) {
  throw new Error('Missing Pinata credentials in environment variables')
}

export const uploadToIPFS = async (file: File): Promise<string> => {
  try {
    console.log('Preparing file upload...')
    
    // 创建表单数据
    const formData = new FormData()
    formData.append('file', file)

    // 添加元数据
    const metadata = JSON.stringify({
      name: file.name,
      keyvalues: {
        timestamp: Date.now().toString(),
        size: file.size.toString(),
        type: file.type
      }
    })
    formData.append('pinataMetadata', metadata)

    // 添加选项
    const options = JSON.stringify({
      cidVersion: 0,
      wrapWithDirectory: false
    })
    formData.append('pinataOptions', options)

    console.log('Uploading to Pinata...')

    const response = await axios.post(PINATA_API_URL, formData, {
      headers: {
        'pinata_api_key': PINATA_API_KEY,
        'pinata_secret_api_key': PINATA_API_SECRET
      },
      maxContentLength: Infinity,
      maxBodyLength: Infinity
    })

    if (!response.data?.IpfsHash) {
      throw new Error('Invalid response from Pinata')
    }

    const ipfsHash = response.data.IpfsHash
    
    // 使用多个 IPFS 网关
    const gateways = [
      `https://gateway.pinata.cloud/ipfs/${ipfsHash}`,
      `https://ipfs.io/ipfs/${ipfsHash}`,
      `https://cloudflare-ipfs.com/ipfs/${ipfsHash}`,
      `https://dweb.link/ipfs/${ipfsHash}`
    ]

    // 测试网关可用性
    for (const gateway of gateways) {
      try {
        const testResponse = await fetch(gateway, { method: 'HEAD' })
        if (testResponse.ok) {
          console.log('File uploaded successfully:', gateway)
          return gateway
        }
      } catch (error) {
        console.warn(`Gateway ${gateway} not available`)
        continue
      }
    }

    // 如果所有网关都不可用，返回第一个网关
    return gateways[0]
  } catch (error: any) {
    console.error('Upload error:', {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status
    })

    if (error.response?.data?.error) {
      throw new Error(`上传失败: ${error.response.data.error.details || error.response.data.error}`)
    } else if (error.response) {
      throw new Error(`上传失败: ${error.response.statusText}`)
    } else if (error.request) {
      throw new Error('网络错误: 无法连接到 IPFS 服务')
    } else {
      throw new Error(`上传错误: ${error.message}`)
    }
  }
}

export const getFromIPFS = async (hash: string): Promise<Response> => {
  const gateways = [
    `https://gateway.pinata.cloud/ipfs/${hash}`,
    `https://ipfs.io/ipfs/${hash}`,
    `https://cloudflare-ipfs.com/ipfs/${hash}`,
    `https://dweb.link/ipfs/${hash}`
  ]

  for (const gateway of gateways) {
    try {
      const response = await fetch(gateway)
      if (response.ok) {
        return response
      }
    } catch (error) {
      console.warn(`Failed to fetch from ${gateway}:`, error)
      continue
    }
  }

  throw new Error('无法从任何 IPFS 网关获取文件')
} 