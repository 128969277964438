import { FileUpload } from './components/FileUpload'
import { motion } from 'framer-motion'
import { Container } from '@chakra-ui/react'

function App() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-white to-indigo-50 py-16">
      <Container maxW="container.md">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <h1 className="text-5xl font-bold bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent mb-4">
            IPFS 匿名文件存储
          </h1>
          <p className="text-gray-600 text-lg">
            安全、匿名、去中心化的文件存储解决方案
          </p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          className="bg-white/80 backdrop-blur-sm rounded-2xl shadow-xl p-8 border border-gray-100"
        >
          <FileUpload />
        </motion.div>
      </Container>
    </div>
  )
}

export default App 