import { Buffer } from 'buffer'

if (typeof global === 'undefined') {
  (window as any).global = window;
}

if (typeof process === 'undefined') {
  (window as any).process = { env: {} };
}

(window as any).Buffer = Buffer;

export {}; 