import React, { useState, useCallback, useRef } from 'react'
import { uploadToIPFS } from '../services/ipfs'
import { motion, AnimatePresence } from 'framer-motion'
import { CloudArrowUpIcon } from '@heroicons/react/24/outline'
import { Box, Text, Link, useToast } from '@chakra-ui/react'

// 文件大小格式化函数
const formatFileSize = (bytes: number): string => {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const sizes = ['Bytes', 'KB', 'MB', 'GB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
}

export const FileUpload: React.FC = () => {
  const [uploading, setUploading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [fileUrl, setFileUrl] = useState<string>('')
  const [dragActive, setDragActive] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const progressIntervalRef = useRef<number>()
  const toast = useToast()

  const handleFileUpload = useCallback(async (file: File) => {
    if (!file) return

    try {
      setSelectedFile(file)
      setUploading(true)
      setUploadProgress(0)

      progressIntervalRef.current = window.setInterval(() => {
        setUploadProgress((prev) => Math.min(prev + 10, 90))
      }, 500)

      const url = await uploadToIPFS(file)
      
      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current)
      }
      setUploadProgress(100)
      setFileUrl(url)

      toast({
        title: "上传成功",
        description: "文件已成功上传到 IPFS",
        status: "success",
        duration: 5000,
        isClosable: true,
      })
    } catch (error: any) {
      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current)
      }
      setUploadProgress(0)
      setSelectedFile(null)
      
      const errorMessage = error.message || '文件上传失败'
      toast({
        title: "上传失败",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      })
      console.error('Upload error:', error)
    } finally {
      setUploading(false)
    }
  }, [toast])

  React.useEffect(() => {
    return () => {
      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current)
      }
    }
  }, [])

  const handleDrag = useCallback((e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true)
    } else if (e.type === "dragleave") {
      setDragActive(false)
    }
  }, [])

  const handleDrop = useCallback((e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    
    const file = e.dataTransfer?.files[0]
    if (file) {
      handleFileUpload(file)
    }
  }, [handleFileUpload])

  return (
    <Box>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="space-y-6"
      >
        <div
          className={`file-drop-zone group ${dragActive ? 'drag-active' : ''}`}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          <input
            type="file"
            onChange={(e) => e.target.files?.[0] && handleFileUpload(e.target.files[0])}
            className="hidden"
            id="file-upload"
            disabled={uploading}
          />
          <label
            htmlFor="file-upload"
            className="flex flex-col items-center cursor-pointer w-full"
          >
            <CloudArrowUpIcon className="w-20 h-20 text-blue-500 group-hover:text-blue-600 transition-colors duration-300" />
            <motion.div 
              className="text-center mt-4"
              whileHover={{ scale: 1.02 }}
            >
              <Text className="text-xl font-semibold text-gray-700 mb-2">
                {uploading ? "正在上传..." : "拖拽文件到此处或点击上传"}
              </Text>
              <Text className="text-sm text-gray-500">
                支持任何类型的文件
              </Text>
              {selectedFile && (
                <Text className="text-sm text-blue-600 mt-2">
                  已选择: {selectedFile.name} ({formatFileSize(selectedFile.size)})
                </Text>
              )}
            </motion.div>
          </label>
        </div>

        <AnimatePresence>
          {uploading && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              className="bg-white rounded-lg p-6 shadow-sm border border-gray-100"
            >
              <Text className="text-sm font-medium text-gray-700 mb-3">
                上传进度：{uploadProgress}%
              </Text>
              <div className="progress-bar">
                <motion.div
                  className="progress-bar-fill"
                  initial={{ width: 0 }}
                  animate={{ width: `${uploadProgress}%` }}
                />
              </div>
            </motion.div>
          )}

          {fileUrl && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="file-link"
            >
              <Text className="text-sm font-medium text-gray-700 mb-2">
                文件链接
              </Text>
              <Link
                href={fileUrl}
                isExternal
                className="text-blue-600 hover:text-blue-700 break-all font-medium"
              >
                {fileUrl}
              </Link>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </Box>
  )
} 